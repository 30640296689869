<template>
	<article id="home" class="wrapper style1">
				<div class="container">
					<div class="row">
						<div class="col-4 col-5-large col-12-medium">
							<span class="image fit"><img src="../assets/images/mediumProfile.jpg" alt="Alex Profile Pic" /></span>
						</div>
						<div class="col-8 col-7-large col-12-medium">
							<header>
								<h1>Hi! I'm <strong>Alex Mackey</strong>.</h1>
							</header>


                            <p>
I like <a href="#speaking">speaking</a> about tech, learning & teaching AppSec, have <a href="#writing">written</a> a couple of books on .NET, created courses for <a href="https://www.pluralsight.com/authors/alex-mackey">Pluralsight</a> and A Cloud Guru and founded a non profit conference called <a href="https://www.dddmelbourne.com">DDD Melbourne</a>.
                            More about me <a href="#here">here</a>
							</p>
							
							<blogposts></blogposts>
							
						</div>
					</div>
				</div>
			</article>

</template>
