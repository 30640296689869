<template>
  <article id="books" class="wrapper style1">
				<div class="container">

<header>
    <h2>Books</h2>
</header>

<div class="row">
    <div class="col-12 col-12-medium col-12-small">

        <p>
            I have written two books for Apress on .NET.
        </p>

        <h3>Introducing .NET 4.5 with Visual Studio 2012</h3>

        <p>
            Microsoft has introduced a large number of changes to the way that the .NET Framework operates. Familiar technologies have being altered, best practices replaced, and developer methodologies adjusted. Many developers find it hard to keep up with the pace of change across .NET's ever-widening array of technologies. The introduction of Windows 8 and its new style of applications only compounds the problem.
        </p>

        <p>
            You may know what's happening in C#, but what about the latest innovations in the cloud? How is that going to affect your work? What possibilities do the new async capabilities bring? What you need is a roadmap. A guide to help you see the innovations that matter and to give you a head start on the opportunities available in the new framework.
        </p>

        <p>
            Introducing .NET 4.5 is designed to provide you with just that roadmap. It serves as a no-nonsense primer that will help experienced .NET developers understand the impact of the new framework and the technologies that co-exist with it. This book will keep you updated on the changes and help you to seize new opportunities confidently and quickly.
        </p>

        <p>
            The book is aimed at existing .NET developers who will be trying to come to grips with .NET 4.5 and Windows 8, which will be changing the way they need to think about creating applications.
        </p>

        <p>
            Im pretty happy with the content of the book and think we give a good introduction to the various new features available in an easy to read format.

            If you want a really deep look at the various changes or a reference to keep on your shelf then this probably <strong>isnt the book for you</strong> (I really like Joseph Albahari’s <a href="http://www.amazon.com/C-5-0-Nutshell-Definitive-Reference/dp/1449320104/ref=sr_1_1?s=books&ie=UTF8&qid=1348884119&sr=1-1&keywords=c%235+in+a+nutshell">C#5 in a nutshell</a>).
            If however you are short of time and want a light hearted and easy to read introduction (and can put up with my <strong>terrible sense of humour)</strong> then I think you will enjoy it.
        </p>

        <p>
            I'll also be donating 50% of my earnings from this book to the <a href="http://www.cancer.org.au/">Cancer Council Australia</a>.
        </p>

        


        <p>
            <a href="http://www.amazon.com/gp/product/1430243325/ref=s9_simh_gw_p14_d0_g14_i1?pf_rd_m=ATVPDKIKX0DER&pf_rd_s=center-2&pf_rd_r=0X6P7QZF7KHM872GR4PM&pf_rd_t=101&pf_rd_p=470938631&pf_rd_i=507846">
                <img src="../assets/images/net45book.png" alt="Introducing .net 4.5 with Visual Studio 2012 book" />
            </a>

        </p>




        <p>
            If you order the book please use the links below as I make slightly more on the purchase at no cost to you through Amazon's affiliate program.
            <br /><br />

            <a href="http://www.amazon.com/gp/product/1430243325/ref=as_li_ss_tl?ie=UTF8&camp=1789&creative=390957&creativeASIN=1430243325&linkCode=as2&tag=simp064-20">Order from Amazon.com</a>

            <br />

            <a href="http://www.amazon.co.uk/gp/product/1430243325/ref=as_li_ss_tl?ie=UTF8&camp=1634&creative=19450&creativeASIN=1430243325&linkCode=as2&tag=simp06-21">Order from Amazon.co.uk</a>

            <br />

            <a href="http://www.apress.com/9781430243328">Apress book page</a>

        </p>


        <h3>
            Introducing .NET 4.0 with Visual Studio 2010
        </h3>





        <h2>What’s different about this book?</h2>
        <p>
            This book is about <strong>breadth</strong> rather than depth. It will get you up to speed quickly on new functionality in just enough depth to get you going but without getting bogged down with too much detail.
        </p>
        <p>
            When something big like Visual Studio 2010 is released I believe developers need and want an overview of what’s new. When they find an area of interest they can then research it further.
        </p>
        <p>
            When writing this book I tried to keep in mind the following ideas:


            <ul>
                <li>Give the reader an introduction to new technologies </li>
                <li>Show how to do the basics that any developer would want to know  </li>
                <li>Produce examples that are as simple as possible but still demonstrate the concept</li>
                <li>Don’t get too bogged down in detail so the book can still be easily read </li>

            </ul>
        </p>


        <p>
            <img src="../assets/images/bookCartoon.jpg" alt="Book cartoon" />
        </p>

            

    </div>
</div>
				</div>
  </article>
</template>