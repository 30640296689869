<template>
<article id="about" class="wrapper style1">
				<div class="container">

<header>
    <h2>About</h2>
</header>

<div class="row">
    <div class="col-12 col-12-medium col-12-small">
<p>
    Experienced technology leader with over 24 years experience with focus on Application Security and Microsoft Platform (.NET Core and Azure).
</p>

<p>
    Currently working as Head of Technology/Principal for development consultancy <a href="https://www.kodez.com.au">Kodez</a> and responsible for leading technology practice. 
</p>
   
<p>
    Previously held various technical and strategic leadership roles including Principal (Readify/Telstra Purple), Staff Engineer - AppSec, (Willow), Practice and Team Lead (Xero, Kiandra IT, Readify).
</p>

<p>
   My experience has ranged from hands on development to leading and supporting multiple teams to business development and pre-sales to advising large public and private organisations on aspects of their IT strategy.
</p>

<p>
	I've worked on a range of IT projects and engagements from Web Applications to APIs to IoT and Digital Twins. 
</p>


<p>
    I have <a href="#books">written</a> two books: Introducing .NET 4.0: With Visual Studio 2010 and Introducing .NET 4.5 published by Apress and spoken at a number of large conferences in Australia, UK, Scandinavia and Ireland including NDC Sydney and Oslo, TechEd/Ignite, Web Directions and Remix. I have developed several courses for online training provider Pluralsight and A Cloud Guru.
</p>

<p>
    I founded (in Australia) and run the community conference <a href="https://www.dddmelbourne.com">www.dddmelbourne.com</a> which has now been running for 11 years with over 700 attendees at the last event. I am active in the development community and was awarded the Microsoft MVP award for 7 years.
</p>

</div>
</div>
				</div>
</article>

</template>
