<template>
<article id="speaking" class="wrapper style1">
				<div class="container">

<header>
    <h2>Speaking</h2>
</header>

<div class="row">
    <div class="col-12 col-12-medium col-12-small">
<p>
    I have spoken at a number of developer events and conferences including Web Directions, Tech-ed, Remix and many smaller events to groups of 5 to 300+.
    <br /> <br />

    I am based in Melbourne, Australia. If you would like me to speak at your user group or conference then please do not hesitate to contact me.
</p>

<p>
    <img src="../assets/images/speaking.jpg" alt="Alex speaking at Web Directions" />

</p>

<h3>Upcoming Talks</h3>


<dl>
    <dd>
        No upcoming talks scheduled.
    </dd>
</dl>


<h3>Recordings</h3>

<dl>

    <dd>
        <a href=" https://youtu.be/bl7wFMwboBM">NDC Melbourne - Jun 23rd 2022  - eXential XSS (Cross Site Scripting)</a>
    </dd>
    <dd>
        <a href="https://www.youtube.com/watch?v=nnuLeQNfruA">NDC Melbourne - Jul 29th 2021 - Introduction to Digital Twins with the Azure Digital Twin Platform</a>
    </dd>

	<dd>
        <a href="https://www.youtube.com/watch?v=61ArSpwDRFE&list=PL03Lrmd9CiGcg2_VkY3AdZs3-bR9jzu4J&index=61">NDC Sydney - Oct 14th 2019 - A Brief History of Cloud</a>
    </dd>
	
	<dd>
        <a href="https://www.youtube.com/watch?v=6EV-3_m0uVk">NDC Oslo - June 17th 2019 - Creating Solutions for Everyone</a>
    </dd>
	
	<dd>
        <a href="https://acloud.guru/series/azure-this-week/view/299">A Cloud Guru - Jul 19th 2019 - Azure This Week</a>
    </dd>
	
	
	<dd>
		<a href="https://www.youtube.com/watch?v=0FIsf7953_k">DIF Hub - Sep 3rd 2019 - A More Connected World</a>
	</dd>
	
    <dd>
        <a href="https://www.youtube.com/watch?v=sGhmkfvHufE">Real Big Things - Aug 26th 2016 - Human Brain Interfaces</a>
    </dd>

    <dd>
        <a href="http://www.webdirections.org/code15/">Web Directions Code - June 25th - Numbers in JS ¯\_(ツ)_/¯ </a>
    </dd>

    <dd>
        <a href="http://melbjs.com/">MelbJS - July 8th 2015 - Numbers in JS</a>
    </dd>

    <dd>
        <a href="http://www.webdirections.org/resources/alex-mackey-harden-ajax/">Web Directions Code - May 1st & 2nd 2014 - Harden up your AJAX</a>
    </dd>

    <dd>
        <a href="http://www.youtube.com/watch?v=CHCN2BjnjXU">Web Directions what do you know? 16th Feb 2014 - Navigation Timing API</a>
    </dd>

    <dd>
        <a href="http://channel9.msdn.com/Events/TechEd/Australia/Tech-Ed-Australia-2011/WEB304?WT.mc_id=soc-n-au-loc--youtube">Teched 31st Aug 2011 - HTML5 the useful bits</a>
    </dd>

    <dd>
        <a href="http://www.youtube.com/watch?v=oFs_dHsYIEI">Teched 30th Aug 2011 - Interview</a>
    </dd>

    <dd>
        <a href="http://www.talkingshopdownunder.com/2010/04/episode-9-alex-mackey-on-vs2010-new.html">Talking Shop 19th April 2010</a>
    </dd>
</dl>


<h3>Completed talks</h3>

<dl>

    <dd>
        <a href="https://www.programmable.tech/">Programmable Melbourne - Mar 19th 2024 - OAuth Oops</a>
    </dd>	

    <dd>
        <a href="https://devops-mel.coriniumintelligence.com/">Modern Devops Melbourne - 9th Oct 2023 - Uncovering Insights From Past Assaults On Build and Deployment Systems</a>
        
    </dd>	

    <dd>
        <a href="https://ndcmelbourne.com/">NDC Melbourne - June 22nd to June 24th 2022 - eXential XSS (Cross Site Scripting)</a>
    </dd>	

	<dd>
        <a href="https://www.youtube.com/watch?v=61ArSpwDRFE&list=PL03Lrmd9CiGcg2_VkY3AdZs3-bR9jzu4J&index=61">NDC Sydney - Oct 14th 2019 - A Brief History of Cloud</a>
    </dd>
	
	<dd>
        <a href="https://www.youtube.com/watch?v=6EV-3_m0uVk">NDC Oslo - June 17th 2019 - Creating Solutions for Everyone</a>
    </dd>
	
	<dd>
        <a href="https://acloud.guru/series/azure-this-week/view/299">A Cloud Guru - Jul 19th 2019 - Azure This Week</a>
    </dd>
	
	
	<dd>
		<a href="https://www.youtube.com/watch?v=0FIsf7953_k">DIF Hub - Sep 3rd 2019 - A More Connected World</a>
	</dd>
	

    <dd>
        <a href="http://ndcsydney.com/speaker/alex-mackey/">NDC Sydney - Aug 14th to 18th 2017 - VR for Web Developers</a>
    </dd>

    <dd>
        <a href="https://archive.buzzconf.io/category/buzzconf-nights/">BuzzConf Nights - May 25th 2017 - VR/AR for fun and profit</a>
    </dd>

    <dd>
        <a href="https://msftignite.com.au/">Ignite Australia - Feb 14th 2017 - Reality Check. AR & VR for Fun & Profit</a>
    </dd>

    <dd>
        <a href="https://msftignite.com.au/">Ignite Australia - Feb 14th 2017 - Building Gooroo.io</a>
    </dd>

    <dd>
        <a href="https://www.youtube.com/watch?v=sGhmkfvHufE">Real Big Things - Aug 26th - Brain Computer Interfaces</a>
    </dd>
    <dd>
        <a href="http://ndcsydney.com/talk/performance-stability-anti-patterns/">NDC Sydney - Aug 1st-5th - Performance and Stability Anti Patterns</a>
    </dd>
    <dd>
        <a href="http://melbjs.com/">MelbJS - Sep 14th - Performance and Stability Anti Patterns</a>
    </dd>
    <dd>
        <a href="http://buzzconf.io/sessions/more-than-human/">Buzz Conf - Nov 14th &amp; 15th - More than human</a>
    </dd>
    <dd>
        <a href="https://msftignite.com.au/">Microsoft Ignite - Nov 17th &amp; 20th - Microsoft Edge for Developers</a>
    </dd>

    <dd>
        <a href="http://webdirections.org/"></a>
    </dd>

    <dd>
        <a href="http://webdirections.org/">What do you know? - Math traps! - May 7th 2015</a>
    </dd>

    <dd>
        <a href="http://campjs.com/#intro-to-webgl">CampJS 23rd May - Intro to WebGL with three.js workshops</a>
    </dd>

    <dd>
        <a href="http://webdirections.org/code14/">Web Directions Code - May 1st & 2nd 2014 - Harden up your AJAX</a>
    </dd>

    <dd>
        <a href="http://www.webdirections.org/blog/what-do-you-know-melbourne-april-2014-wrap-up/">Web Directions what do you know? April 4th 2014 - Just add WebGL</a>
    </dd>


    <dd>
        <a href="http://melbjs.com/">K-Nights 14th Nov 2013 - Whats new in .net 4.5.1?</a>
    </dd>

    <dd>
        <a href="http://melbjs.com/">Kiandra 1st Nov 2013 - NancyFx</a>
    </dd>

    <dd>
        <a href="http://melbjs.com/">MelbJs 11th Sep 2013 - Intro to WebGL</a>
    </dd>

    <dd>
        <a href="http://www.deveve.net">Deveve.net Aug 28th - Intro to WebGL</a>
    </dd>

    <dd>
        <a href="http://code13.webdirections.org/">Web Directions Code - 3rd May 2013 - TypeScript and Terminators </a>
    </dd>

    <dd>
        <a href="http://whatdoyouknow.webdirections.org/melbourne">Web Directions what do you know? 4th April 2013 - RX in a rush</a>
    </dd>



    <dd>
        <a href="http://www.adnug.com/notices.htm">Adelaide User Group 13th March 2013 - HTML 5 and friends</a>
    </dd>

    <dd>
        <a href="http://kiandrait_intro_visual_studio.eventbrite.com.au/">Introducing VS2012 and .net 4.5 5th Dec 2012</a>
    </dd>


    <dd>
        <a href="http://melbjs.com/">Melbourne JS 14th Nov 2012 - Hi(Ecma)5!</a>
    </dd>


    <dd>
        <a href="http://readify.net/our-events?event-id=D64844B2-61DA-4B92-9C4A-778A0A110093">Readify Dev Breakfast (Perth) 31st Aug - HTML5 CSS 3 and friends</a>
    </dd>



    <dd>
        <a href="http://readify.net/our-events?event-id=E5457277-3D7B-4161-9AD4-FEF67D50B258">Building the users web 22nd Aug - Bringing TDD to the browser </a>
    </dd>



    <dd>
        <a href="http://readify.net/our-events?event-id=E5457277-3D7B-4161-9AD4-FEF67D50B258">Building the users web 22nd Aug – Debugging tools and tricks for every web developer</a>
    </dd>


    <dd>
        <a href="http://www.dddmelbourne.com">DDD Melbourne 28th July HTML5 CSS 3 and friends</a>
    </dd>


    <dd>
        <a href="http://readify.net/our-events?event-id=251D094D-2A80-483F-8455-BF8510DDAAA9">18th July Readify Dev Breakfast - HTML5 CSS 3 and friends</a>
    </dd>


    <dd>
        <a href="http://deveve10.eventbrite.com.au">DevEve 15th March - Mobile Madness</a>
    </dd>

    <dd>
        <a href="http://whatdoyouknow.webdirections.org/melbourne">Web Directions what do you know? 16th Feb - Navigation Timing API</a>
    </dd>

    <dd>
        <a href="http://australia.msteched.com">Teched 31st Aug - HTML5 the useful bits</a>
    </dd>

    <dd>
        <a href="http://australia.msteched.com">Teched 30th Aug - Avoiding JavaScript Booby Traps</a>
    </dd>

    <dd>
        <a href="http://www.deveve.net">DevEvening 18th July - Avoiding JavaScript Booby Traps</a>
    </dd>

    <dd>
        <a href="http://readify.net/our-events">Readify 17th July - Avoiding JavaScript Booby Traps</a>
    </dd>

    <dd>
        <a href="http://readify.net/our-events">Readify 10th July - The future of the MS platform</a>
    </dd>

    <dd>
        <a href="http://readify.net/our-events">Remix 1st June - Dev's dive into Internet Explorer 9</a>
    </dd>

    <dd>
        <a href="http://readify.net/our-events">Readify 18th May - Dev's dive into Internet Explorer 9</a>
    </dd>

    <dd>
        <a href="http://readify.net/our-events">Readify 6th April - Dev's dive into Internet Explorer 9</a>
    </dd>

    <dd>
        <a href="http://www.deveve.net">Deveve.net 7th Dec - HTML 5 the useful bits</a>
    </dd>

    <dd>
        <a href="http://codecampoz.com/agenda/">Code Camp OZ 21st Nov - HTML 5 the useful bits</a>
    </dd>

    <dd>
        <a href="http://readify.net/our-events">Readibrekie 18th Nov - HTML 5 the useful bits</a>
    </dd>

    <dd>
        <a href="http://readify.net/our-events">Readibrekie 16th Nov - HTML 5 the useful bits</a>
    </dd>

    <dd>
        <a href="http://readify.net/our-events">Readibrekie 11th Nov - HTML 5 the useful bits</a>
    </dd>

    <dd>
        <a href="http://australia.msteched.com/">Teched 24th - 27th Aug - VS2010 IDE</a>
    </dd>

    <dd>
        <a href="http://readify.net/Community/RDNDevDays.aspx">RDN dev day Sydney 13th June - ASP.net 4 and VS2010/.net 4</a>
    </dd>

    <dd>
        <a href="http://readify.net/Community/RDNDevDays.aspx">RDN dev day Melbourne 7th June - ASP.net 4 and VS2010/.net 4</a>
    </dd>


    <dd>
        <a href="http://www.microsoft.com/australia/remix/default.aspx">Remix 10 1st and 2nd of June - ASP.net 4</a>
    </dd>

    <dd>
        <a href="http://www.microsoft.com/australia/remix/default.aspx">Remix 10 1st and 2nd of June - Harder, Faster, Better, Stronger! VS2010 IDE Enhancements </a>
    </dd>

    <dd>
        <a href="http://www.australianalm.com.au/">Australian ALM Conference 13th and 14th April - .NET 4 – what you need to know</a>
    </dd>

    <dd>
        <a href="http://www.australianalm.com.au/">Australian ALM Conference 13th and 14th April - Harder, Faster, Better, Stronger! VS2010 IDE Enhancements</a>
    </dd>

    <dd>
        <a href="http://www.deveve.net/">DevEvening (Australia) 28th Jan Parallelization in .net 4</a>
    </dd>

    <dd>
        <a href="http://www.victoriadotnet.com.au/">Victoria.net 13th Oct VS2010 - IDE</a>
    </dd>

    <dd>
        <a href="http://devevening11.eventbrite.com/">DevEvening 3rd Sep - VS2010 - IDE</a>
    </dd>

    <dd>
        <a href="http://epicenter.ie/">Epicenter 28th Aug - VS2010 - IDE </a>
    </dd>

    <dd>
        <a href="http://www.nxtgenug.net/ViewEvent.aspx?EventID=192"> NxtGen Oxford 5th May - An intro to Oslo technologies</a>

    </dd>

    <dd>
        <a href="http://www.developerdeveloperdeveloper.com/webdd09/ProposedSessions.aspx">WebDD 09 18th April - Whats new in .net 4?</a>

    </dd>

    <dd>
        <a href="http://developerdeveloperdeveloper.com/belfast/ViewSession.aspx?SessionID=213">DDD Belfast 4th April - Whats new in .net 4?</a>

    </dd>

    <dd>
        <a href="http://www.vbug.co.uk/Events/April-2009/VBUG-London-Whats-good-in-NET-4-and-Visual-Studio-2010.aspx"> Vbug London 7th April - Whats new in .net 4? </a>

    </dd>

    <dd>
        DD6 - Arabian Nights - Non technical issues of developing an application in Dubai

    </dd>

    <dd>
        DevEvening - Silverlight 101
    </dd>

    <dd>
        DevEvening - WCF basics

    </dd>
</dl>

</div>
</div>
				</div>
</article>
</template>
