import { createApp } from 'vue'
import App from './App.vue'
import Home from './views/Home'
import Speaking from './views/Speaking'
import Books from './views/Books'
import About from './views/About'
import BlogPosts from './views/BlogPosts'

require('./assets/css/fontawesome-all.min.css')
require('./assets/css/main.css')

const app = createApp(App)

app.component('home', Home)
app.component('speaking', Speaking)
app.component('books', Books)
app.component('about', About)
app.component('blogposts', BlogPosts)

app.mount('#app')