<template>

<h3>Latest Posts</h3>

 <div class="blog-post" v-for="item in items"  v-bind:key="item.ID">

  <span class="blog-post-date" v-text="new Date(item.date).toLocaleDateString(
  'en-gb',
  {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
)"></span>


  <a v-bind:href="item.URL" v-html="item.title"></a>
   
 </div>

</template>
 
<script>
 
export default {
 
       data() {
              return {
                     items: []
              }
       },
       created () {
              this.fetchPosts();
       },

       methods: {

              fetchPosts() {

                     const blogPostRequest = new Request('https://public-api.wordpress.com/rest/v1.1/sites/alexjmackey.wordpress.com/posts/?number=3&pretty=true');
                    
                     fetch(blogPostRequest)
                     .then(response => response.json())
                     .then(data => {
                           this.items = data.posts
                     })            
              }
       }
}
</script>